import React from "react";

export const BoldRightArrow = ({height = 18, width = 14}) => {
  return (
    <svg
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 18 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.0296 5.74383C15.4753 5.18956 14.5767 5.18956 14.0224 5.74382L9.23234 10.5339C8.67807 11.0882 8.67807 11.9868 9.23234 12.5411C9.78661 13.0953 10.6853 13.0953 11.2395 12.5411L16.0296 7.75101C16.5839 7.19674 16.5839 6.29809 16.0296 5.74383Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.029 7.7484C15.4759 8.30386 14.5773 8.30579 14.0218 7.75271L9.26172 3.01295C8.70627 2.45987 8.70434 1.56122 9.25741 1.00576C9.81048 0.450304 10.7091 0.448374 11.2646 1.00145L16.0247 5.74122C16.5801 6.29429 16.5821 7.19294 16.029 7.7484Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.8484 6.74697C14.8484 7.53082 14.213 8.16626 13.4291 8.16626L2.25227 8.16626C1.46842 8.16626 0.832981 7.53082 0.832981 6.74697C0.832981 5.96311 1.46842 5.32768 2.25227 5.32768L13.4291 5.32768C14.213 5.32768 14.8484 5.96312 14.8484 6.74697Z'
        fill='white'
      />
    </svg>
  );
};
