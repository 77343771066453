import React from "react";

import * as styles from "./styles.module.scss";

const Buffer = ({buffer = 0, mobileBuffer = 0}) => (
  <>
    <div
      style={{width: "100%", marginTop: buffer}}
      className={styles.hideInMobile}
    />
    <div
      style={{width: "100%", marginTop: mobileBuffer}}
      className={styles.hideInDesktop}
    />
  </>
);

export default Buffer;
