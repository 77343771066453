// extracted by mini-css-extract-plugin
export var aboveCalculatorWrap = "styles-module--aboveCalculatorWrap--2B77J";
export var active = "styles-module--active--gb-6l";
export var answer = "styles-module--answer--XMVjN";
export var bg = "styles-module--bg--17M_a";
export var bgSvg = "styles-module--bgSvg--2jTzU";
export var bottom = "styles-module--bottom--RJlHG";
export var calculatorWrap = "styles-module--calculatorWrap--2F1O3";
export var check = "styles-module--check--RYwbG";
export var cta = "styles-module--cta--3CDYX";
export var ctaContainer = "styles-module--ctaContainer--3d-K-";
export var customerLogoImg = "styles-module--customerLogoImg--2a5ji";
export var customerLogos = "styles-module--customerLogos--2U9mN";
export var customerLogosHeading = "styles-module--customerLogosHeading--7PBiC";
export var customerLogosImages = "styles-module--customerLogosImages--3olNj";
export var dark = "styles-module--dark--3xES2";
export var description = "styles-module--description--1M1FQ";
export var designElement = "styles-module--designElement--2HWrN";
export var dot = "styles-module--dot--11mW8";
export var emailInputContainer = "styles-module--emailInputContainer--e-NUI";
export var emailInputField = "styles-module--emailInputField--1xkk9";
export var faq = "styles-module--faq--n2TXB";
export var faqWrap = "styles-module--faqWrap--2iGaM";
export var faqs = "styles-module--faqs--3Mox4";
export var footerWrap = "styles-module--footerWrap--2T42k";
export var getStartedCTA = "styles-module--getStartedCTA--1Ujfz";
export var getStartedUrlText = "styles-module--getStartedUrlText--26R-L";
export var heading = "styles-module--heading--3XPkx";
export var heroWrap = "styles-module--heroWrap--2HVfP";
export var hideInDesktop = "styles-module--hideInDesktop--2HFEk";
export var hideInMobile = "styles-module--hideInMobile--2GGWt";
export var list = "styles-module--list--26D0n";
export var loader = "styles-module--loader--2HQxL";
export var plan = "styles-module--plan--CCxeu";
export var planCardsContainer = "styles-module--planCardsContainer--1Z_7l";
export var planPriceContainer = "styles-module--planPriceContainer--2_Q7N";
export var plansWrap = "styles-module--plansWrap--2m68w";
export var popular = "styles-module--popular--yxppl";
export var priceImageImg = "styles-module--priceImageImg--1u4cN";
export var priceSubText = "styles-module--priceSubText--1Gm7q";
export var pricingGetStartedSection = "styles-module--pricingGetStartedSection--2MgFJ";
export var pricingHeading = "styles-module--pricingHeading--_y0w1";
export var purpleWrap = "styles-module--purpleWrap--1860S";
export var question = "styles-module--question--1UyfY";
export var rate = "styles-module--rate--3o8wd";
export var reviewsWrap = "styles-module--reviewsWrap--33Jti";
export var spin = "styles-module--spin--24XeU";
export var subHeadingPricing = "styles-module--subHeadingPricing--3BxlN";
export var title = "styles-module--title--1JA91";
export var top = "styles-module--top--1MsUG";