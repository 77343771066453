import * as React from "react";

export const PlansBGSVG = ({className, onClick}) => (
  <svg
    className={className}
    onClick={onClick}
    viewBox='0 0 1440 432'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M0 86.3198L1440 0V344L0 432V86.3198Z' fill='#FFF2F2' />
  </svg>
);

export const PlansMobileBGSVG = ({className, onClick}) => (
  <svg
    className={className}
    onClick={onClick}
    viewBox='0 0 359 396'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M0 50.3198L360 0V344L0 396V50.3198Z' fill='#FFF2F2' />
  </svg>
);
