import {StyleExtractor, isFunction, isValidString} from "@kubric/utils";
import {graphql} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import Check from "react-feather/dist/icons/check";
import ChevronDown from "react-feather/dist/icons/chevron-down";
import Loader from "react-feather/dist/icons/loader";

import LastSection from "../../components/AboveFooterSection";
import Buffer from "../../components/Buffer";
import Layout from "../../components/Layout";
import StructuredData from "../../components/StructuredData";
import SanityRichText from "../../components/commons/SanityRichText";
import {ArrowRightIcon} from "../../components/icons/Arrows";
import {BoldRightArrow} from "../../components/icons/BoldRightArrow";
import MMReviews from "../../components/pages/modemagic/Reviews";
import {
  PlansBGSVG,
  PlansMobileBGSVG,
} from "../../components/pages/roi/PlansBGSVG";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import {emailCapture} from "../../utils/emailCapture";
import "../styles.scss";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const CTAStates = {
  IDLE: "idle",
  SUCCESS: "success",
};

const PlanCard = ({
  title,
  planRate,
  priceSubText = undefined,
  rateSubText,
  list,
  description,
  ctaText,
  emailPlaceholder,
  ctaThanksText,
  i, // index
  ctaUrl = undefined,
  customerLogosHeading,
  customerLogos = [],
}) => {
  const [showEmailField, setShowEmailField] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [ctaState, setCTAState] = React.useState(CTAStates.IDLE);
  const [loading, setLoading] = React.useState(false);

  const planCTAEvent = `add-mm-app-${title.split(" ")[0].toLowerCase()}plan`;

  return (
    <div className={styler.get(["plan", i % 2 === 0 && "dark"])}>
      {i % 2 === 0 && <span className={styler.get("popular")}>popular</span>}
      <span className={styler.get("title")}>{title}</span>
      {i % 2 === 0 ? (
        <img
          alt='stars icon'
          src='https://media.kubric.io/api/assetlib/6d4e23a6-934c-4d3f-a360-ab922a1f0c58.png'
          className={styler.get("priceImageImg")}
        />
      ) : (
        <span className={styler.get("planPriceContainer")}>
          {priceSubText && (
            <span className={styler.get("priceSubText")}>{priceSubText}</span>
          )}
          <span className={styler.get("rate")}>
            <span>{planRate}</span>
            <span>
              <span>{rateSubText.substring(0, 3)}</span>
              <span>{rateSubText.substring(3)}</span>
            </span>
          </span>
        </span>
      )}

      <span className={styler.get("description")}>{description}</span>
      {ctaUrl ? (
        <OutboundLink
          className={styler.get("cta")}
          href={ctaUrl}
          rel='noopener noreferrer'
          target={ctaUrl === "#roiCalculator" ? "_self" : "_blank"}
          id={planCTAEvent}
        >
          {ctaText}
        </OutboundLink>
      ) : (
        <>
          {showEmailField ? (
            <div className={styler.get("emailInputContainer")}>
              <input
                onChange={(e) => setEmail(e.target.value)}
                className={styler.get("emailInputField")}
                placeholder={emailPlaceholder}
              />
              <button
                type='button'
                onClick={() => {
                  if (isValidString(email)) {
                    setLoading(true);
                    if (isFunction(window?.gtag)) {
                      window?.gtag("event", "submitted_email_scale_plan", {
                        email,
                      });
                    }
                    emailCapture({email}).then(() => {
                      setCTAState(CTAStates.SUCCESS);
                      setLoading(false);
                      setShowEmailField(false);
                    });
                  }
                }}
              >
                {loading ? (
                  <Loader width={24} className={styler.get("loader")} />
                ) : (
                  <BoldRightArrow />
                )}
              </button>
            </div>
          ) : (
            <>
              {ctaState === CTAStates.IDLE ? (
                <button
                  type='button'
                  id='talk-to-us'
                  className={styler.get("cta")}
                  onClick={() => {
                    if (ctaState === CTAStates.IDLE) {
                      setShowEmailField(true);
                    }
                  }}
                >
                  {ctaText}
                </button>
              ) : (
                <div className={styler.get("cta")}>{ctaThanksText}</div>
              )}
            </>
          )}
        </>
      )}
      <hr />
      <ul className={styler.get("list")}>
        {list.map((li, i) => (
          <li key={`description_${i + 1}`}>
            <Check className={styler.get("check")} />
            {li}
          </li>
        ))}
      </ul>
      <div className={styler.get("customerLogos")}>
        <div className={styler.get("customerLogosHeading")}>
          {customerLogosHeading}
        </div>
        <div className={styler.get("customerLogosImages")}>
          {customerLogos.map((logo) => (
            <GatsbyImage
              alt={logo?.alt}
              image={logo?.image?.asset?.gatsbyImageData}
              className={styler.get("customerLogoImg")}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const num = (str) => str.match(/\d+/);

const RoiPage = ({data}) => {
  const isMobile = useMediaQuery("(max-width: 800px)");
  const seoData = data.allSanitySeoMeta.nodes[0];
  const {
    faqHeading,
    heading,
    subHeading,
    getStartedUrlText,
    getStartedCTA,
    faqs = [],
    plans: roiPlans = [],
  } = data.allSanityRoiPage.nodes[0] ?? {};
  const plans = React.useMemo(
    () =>
      roiPlans.map((plan) => ({
        ...plan,
        planRate: plan.rate,
        rate: num(plan.rate) ? parseInt(num(plan.rate)[0], 10) : 0,
      })),
    [roiPlans.length]
  );
  const {midSection: {_rawText} = {}, lastSection} =
    data.allSanityModemagic3Page.nodes[0] ?? {};
  const {reviews} = data.allSanityModemagicPage.nodes[0] ?? {};

  const [faq, setFaq] = React.useState();
  const [activePlanIndex, setActivePlanIndex] = React.useState(1);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    dots: true,
    nextArrow: <ArrowRightIcon color='#CCCFD4' />,
    prevArrow: <ArrowRightIcon color='#CCCFD4' />,
    appendDots: () => (
      <ul>
        <li
          className={styler.get(["dot", activePlanIndex === 0 && "active"])}
        />
        <li
          className={styler.get(["dot", activePlanIndex === 1 && "active"])}
        />
      </ul>
    ),
    afterChange: (idx) => {
      setActivePlanIndex(idx);
    },
  };

  return (
    <Layout
      type='modemagic'
      mmv3
      seoData={seoData}
      headerProps={{showMMCta: true}}
    >
      <StructuredData />
      <Buffer buffer={81} mobileBuffer={30} />

      {/* Hero Section */}
      <section
        className={styler.get(["heroWrap", "full-bleed", "pricingHeading"])}
      >
        <h1 className={styler.get("heading")}>{heading}</h1>
      </section>
      {/* Sub heading */}
      <section className={styler.get("subHeadingPricing")}>
        <p className={styler.get("subHeading")}>{subHeading}</p>
      </section>

      {!isMobile && (
        <section className={styler.get("pricingGetStartedSection")}>
          <div className={styler.get("ctaContainer")}>
            <input
              value={getStartedUrlText}
              className={styler.get("getStartedUrlText")}
            />
            <OutboundLink
              className={styler.get("getStartedCTA")}
              href={getStartedCTA.url}
              target='_blank'
              rel='noopener noreferrer'
            >
              {getStartedCTA.text}
            </OutboundLink>
          </div>
        </section>
      )}

      {/* Plans Section */}
      <section className={styler.get("plansWrap", "full-bleed")}>
        <PlansBGSVG className={styler.get(["bgSvg", "hideInMobile"])} />
        <PlansMobileBGSVG className={styler.get(["bgSvg", "hideInDesktop"])} />
        <div className={styler.get("planCardsContainer")}>
          {plans.map((plan, i) => (
            <PlanCard {...plan} key={plan._key} i={i + 1} />
          ))}
        </div>
      </section>

      {/* Purple Section */}
      <section className={styler.get("purpleWrap", "full-bleed")}>
        <h2 className={styler.get("heading")}>
          <SanityRichText blocks={_rawText} className='text-center' />
        </h2>
      </section>

      {/* FAQs Section */}
      <section className={styler.get("faqWrap", "full-bleed")}>
        <h2 className={styler.get("heading")}>{faqHeading}</h2>
        <div className={styler.get("faqs")}>
          {faqs.map(({answer, question, _key}, i) => (
            <div
              className={styler.get(["faq", i === faq && "active"])}
              key={_key}
              onClick={() => setFaq(faq === i ? undefined : i)}
            >
              <div className={styler.get("question")}>
                <span>{question}</span>
                <ChevronDown />
              </div>
              <span className={styler.get("answer")}>{answer}</span>
            </div>
          ))}
        </div>
      </section>

      {/* Reviews Section */}
      <section className={styler.get("reviewsWrap", "full-bleed")}>
        <MMReviews data={reviews} isMobile={isMobile} />
      </section>

      {/* Footer Section */}
      <section className={styler.get("footerWrap", "full-bleed")}>
        <LastSection data={lastSection} hideTicketSectionInDesktop />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "pricing"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityRoiPage {
      nodes {
        faqHeading
        heading
        getStartedUrlText
        subHeading
        getStartedCTA {
          text
          url
        }
        faqs {
          answer
          question
          _key
        }
        plans {
          _key
          title
          rate
          priceImage {
            image {
              asset {
                gatsbyImageData
              }
            }
          }
          rateSubText
          priceSubText
          list
          description
          ctaText
          emailPlaceholder
          ctaThanksText
          ctaUrl
          customerLogosHeading
          customerLogos {
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allSanityModemagic3Page: allSanityModemagicPage(
      filter: {page: {eq: "mmv3"}}
    ) {
      nodes {
        midSection {
          _rawText
        }
        lastSection {
          _rawHeading
          _rawFinalHeading
          _rawDescription
          cta
          placeholder
        }
      }
    }
    allSanityModemagicPage(filter: {page: {eq: "mm"}}) {
      nodes {
        reviews {
          _rawHeading
          _rawMobileHeading
          fReview {
            person
            text
            designation
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
          }
          sReview {
            person
            text
            designation
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
          }
        }
      }
    }
  }
`;

export default RoiPage;
